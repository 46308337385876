import { useFormikContext } from "formik";
import { useState, useEffect } from "react";

import dayjs from "dayjs";
import PassportField from "./upload-passport/input-field";

import { monthsLong } from "utils/Utils";

const ImageInput = ({ index, setErrImg, departureDate }) => {
  const { setFieldValue, setFieldError, values } = useFormikContext();

  useEffect(() => {
    if (
      values[`documents${index}`] &&
      values[`documents${index}`]?.length > 0
    ) {
      const documents = values[`documents${index}`];
      documents[0].expiryDate =
        values["expDateYear" + index] +
        "-" +
        (values["expDateMonth" + index]?.length === 2
          ? values["expDateMonth" + index]
          : monthsLong[values["expDateMonth" + index]]) +
        "-" +
        values["expDateDay" + index];
      setFieldValue(`documents${index}`, documents);
    }
  }, [values]);

  const handlePassportResponse = (travelerData) => {
    const date = travelerData?.birthDate?.split("-");
    const documents = [
      {
        number: travelerData?.documentNumber,
        expiryDate: travelerData?.expirationDate,
        issuanceCountry: travelerData?.issuingState,
        nationality: travelerData?.nationality,
        documentType: "PASSPORT",
        holder: true,
      },
    ];
    setFieldValue(`firstName${index}`, travelerData?.firstName);
    setFieldValue(`lastName${index}`, travelerData?.lastName);
    setFieldValue(`gender${index}`, travelerData?.sex?.toUpperCase());
    setFieldValue(`dateOfBirthYear${index}`, date[0]);
    setFieldValue(`dateOfBirthMonth${index}`, date[1]);
    setFieldValue(`dateOfBirthDay${index}`, date[2]);

    setFieldValue(
      `expDateDay${index}`,
      dayjs(travelerData?.expirationDate).format("DD")
    );
    setFieldValue(
      `expDateMonth${index}`,
      dayjs(travelerData?.expirationDate).format("MM")
    );
    setFieldValue(
      `expDateYear${index}`,
      dayjs(travelerData?.expirationDate).format("YYYY")
    );
    setFieldValue(`docNumber${index}`, travelerData?.documentNumber);
    setFieldValue(`documents${index}`, documents);

    let isValid = dayjs(travelerData?.expirationDate).diff(
      dayjs(departureDate),
      "month"
    );

    if (isValid < 6) {
      setFieldError(
        `expDateYear${index}`,
        "Expiration Passport Date is Invalid"
      );
    }
  };

  return (
    <PassportField
      setErrImg={setErrImg}
      handlePassportResponse={handlePassportResponse}
    />
  );
};

export default ImageInput;
